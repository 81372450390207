import { CountryCode } from '@collective/utils/shared';

export const COLLECTIVE_WEBSITE_URL = 'https://www.collective.work';

// redirect query param used to go back to previous page after login
export const REDIRECT_QUERY_KEY = 'redirect';
export const GOOGLE_SSO_ERROR_QUERY_KEY = 'google-sso-error';
export const LINKEDIN_SSO_ERROR_QUERY_KEY = 'linkedIn-sso-error';

export const FALLBACK_LANGUAGE = 'en';
export const LANGUAGE_EN = 'en';
export const LANGUAGE_FR = 'fr';

export const COLLECTIVE_COMPANY_INFORMATION = {
  name: 'Collective',
  email: 'finance@collective.work',
  address: '9 Rue Des Colonnes',
  zipCode: '75002',
  city: 'Paris',
  country: CountryCode.FR,
  companyNumber: '892760539',
  vatNumber: 'FR79892760539',
  shareCapital: '2 106,03 €',
  userFullName: '',
};

export const COLLECTIVE_BANKING_INFORMATION = {
  accountHolder: 'Collective',
  bank: 'Qonto',
  iban: 'FR7616958000013754663869320',
  bic: 'QNTOFRP1XXX',
};

export const DEFAULT_VAT = 20;

export const DEFAULT_MILESTONE_QUANTITY = 1;

// Default value used to generate the first invoice reference of a user
export const DEFAULT_INVOICE_REFERENCE_NUMBER = 1;

export const PDF_EXTENSION = 'pdf';

// Public page events
export enum PageTrackedKeys {
  Escape = 'Escape',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
}

export const PAGE_READY_EVENT = 'pageReady';
export const PAGE_KEYDOWN_EVENT = 'keydown';

// Launchdarkly anonymous user key
export const LD_ANONYMOUS_KEY = 'anonymous';

export const PUBLIC_PAGE_COVER_RATIO = 4.5; // 1440 / 320
export const CASE_STUDY_IMAGE_WIDTH = 232;
export const CASE_STUDY_IMAGE_HEIGHT = 174;

// Default limit for project types
export const PROJECT_TYPES_LIMIT = 35;
export const FEATURED_PROJECT_TYPES_LIMIT = 4;

// Marketplace
export const MARKETPLACE_EMAIL_COOKIE = 'marketplace-email';
export const MARKETPLACE_EMAIL_COOKIE_EXPIRATION_DAYS = 365;

// TEST EMAILS
export const TEST_EMAIL_PREFIX = 'test-emails-datadog-e2e+';
export const TEST_EMAIL_SYNTHETICS_DOMAIN = 'synthetics.dtdg.eu';
export const TEST_EMAIL_DATADOG_DOMAIN = 'email.datadog.test';
export const EMAIL_COLLECTIVE_DOMAIN = 'collective.work';

// TEST DATADOG CLIENT NAME IDENTIFIER
export const TEST_DATADOG_CLIENT_NAME =
  'datadog-test-clqe27plw000008jwh84b96wg';

// Unique toast ids
export const INVALID_SCHEMA_ERROR_TOAST_ID = 'invalid-schema-error-toast';
export const REFRESH_ERROR_TOAST_ID = 'refresh-error-toast';
export const UNEXPECTED_ERROR_TOAST_ID = 'unexpected-error-toast';
export const NETWORK_ERROR_TOAST_ID = 'network-error-toast';

// Maximum number of case studies added when accepting an opportunity
export const OPPORTUNITY_CASE_STUDIES_LIMIT = 3;

// Members in opportunity application
export const MEMBER_DESCRIPTION_MAX_LENGTH = 200;
export const MEMBER_TITLE_MAX_LENGTH = 80;

// Opportunity application process footer height
export const OPPORTUNITY_APPLICATION_FOOTER_HEIGHT = '72px';

// Terms and conditions version
export const CURRENT_TERMS_AND_CONDITIONS_VERSION = 1;

export const INVOICE_STATEMENT_DEFAULT_LANGUAGE = 'fr';

export const INVOICE_STATEMENT_PREVIEW_IBAN = 'FRXXXXXXXXXXXXXXXXXXXXXXXXX';

export const INVOICE_STATEMENT_PREVIEW_BIC = 'XXXXXXXXXXX';

export const MARKETPLACE_SLUG = 'browse';
export const PUBLIC_PREFIX = 'public';

export const NUMBER_OF_FRACTION_DIGITS = 2;

export const REFERENCE_HASH_SIZE = 6;

export const REFERENCE_GENERATION_MAX_RETRIES = 10;

// File size limits in MB
export const DEFAULT_FILE_SIZE_LIMIT_MB = 10;
export const DOCUMENT_LOGO_FILE_SIZE_LIMIT_MB = 5;
export const CHAT_FILE_SIZE_LIMIT_MB = 10;
export const PROPOSAL_FILE_SIZE_LIMIT_MB = 25;
export const PROJECT_FUNNEL_PUBLIC_DOCUMENT_FILE_SIZE_LIMIT_MB = 50;

// Shortlist
export const SHORTLIST_NOT_SHORTLISTED_GROUP_NAME = '__NOT_SHORTLISTED_GROUP__';
export const SHORTLIST_NOT_SHORTLISTED_CANCELLED_BY_ADMIN_GROUP_NAME =
  '__NOT_SHORTLISTED_CANCELLED_BY_ADMIN_GROUP__';
export const SHORTLIST_CANCELLED_BY_ADMIN_GROUP_NAME =
  '__CANCELLED_BY_ADMIN_GROUP__';
export const SHORTLIST_TO_BE_REVIEWED_GROUP_NAME = '__TO_BE_REVIEWED_GROUP__';
export const SHORTLIST_DEFAULT_GROUP_NAME = '__DEFAULT_GROUP__';

export const DISINTERMEDIATED_INVOICE_FEE = 2.9;

export const PROJECT_NAME_MAX_LENGTH = 128;

export const USER_SEARCH_RESULTS_PER_PAGE = 10;
export const USER_SEARCH_LIST_RESULTS_PER_PAGE = 30;
export const JOBS_SEARCH_RESULTS_PER_PAGE = 10;
export const PUBLIC_JOBS_SEARCH_RESULTS_PER_PAGE = 30;

// Searches

// number made to impress recruiters and freelancers that we have a large supply,
// to be updated as we go
export const BASE_SUPPLY_COUNT = 40_000;
export const BASE_SUPPLY_COUNT_DISPLAY = '40 000+';
export const ALL_SUPPLY_COUNT_DISPLAY = '300 000+';

export const RESULT_COUNT_TO_SHOW_IN_NETWORK_SEARCH = 6;
